// src/StoryStartPage.js
import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import SkeletonLoader from '../modules/SkeletonLoader';
import { Link } from 'react-router-dom';
import { FaSyncAlt } from 'react-icons/fa';

import { useStory } from '../context/StoryContext';
import { useUser } from '../context/UserContext';

import { demo_prompts } from './DemoStory';

import './story-page.css';

import API from '../utils/api_axios';
// axios.defaults.baseURL = `${window.location.protocol}//${window.location.hostname}:3013`;

// import { __DEV__ } from '../DEV';
const __DEV__ = false;

const StoryGenStartPage = () => {
    const { userId, 
            loggedIn,
            demoMode,
            activeProfileId, 
            setStoryPrompt,
            imageGenerator, 
            setImageGenerator,
            model, 
            setModel } = useStory();
    const { accountType } = useUser();

    const [inputValue, setInputValue] = useState('');
    const [prompts, setPrompts] = useState(null);
    const [hasBeenCalled, setHasBeenCalled] = useState(false);
    // const [imageGenerator, setImageGenerator] = useState('');
    // const [model, setModel] = useState('');

    const isAdminOrDev = accountType === 'admin' || __DEV__;

    // ========================================= Page useEffect =========================================
    useEffect(() => {
        console.log("StoryStartPage useEffect");
        console.log("activeProfileId:", activeProfileId);
        console.log("loggedIn:", loggedIn);
        console.log("hasBeenCalled:", hasBeenCalled);

        if (!hasBeenCalled) {
            setPrompts(null);
        }
        
        if (__DEV__ || !loggedIn) {
            setPrompts(demo_prompts);
        } else if (!__DEV__ && !hasBeenCalled && activeProfileId && loggedIn) {
            if (isAdminOrDev && (!imageGenerator || !model)) {
                return;
            }
            console.log("activeProfileId:", activeProfileId);

            PopulatePrompts();

            setHasBeenCalled(true);
        } 
    }, [activeProfileId, hasBeenCalled, imageGenerator, model]);
    // ========================================= Page useEffect =========================================

    // ========================================= Page functions =========================================
    function PopulatePrompts() {        
        const profileId = activeProfileId;
        const requestBody = { userId, profileId, model };
        if (isAdminOrDev) {
            Object.assign(requestBody, { imageGenerator, model });
        } else {
            Object.assign(requestBody, { imageGenerator: "Flux", model: "gpt-4o-mini" });
        }
        API.post('/api/story/start', requestBody)
            .then((response) => {
                if (response.data.authorResponse) {
                    const promptsData = response.data.authorResponse.map(prompt => prompt.prompt);
                    setPrompts(promptsData);
                }
            })
            .catch((error) => {
                console.error('Error fetching prompts:', error);
                setPrompts([error.response.data.error]);
            });
    }

    function resizeInput(event) {
        const inputField = event.target;
        const lineHeight = parseInt(getComputedStyle(inputField).lineHeight);
        // console.log(lineHeight);
        const maxLines = 5;
        const maxHeight = lineHeight * maxLines;
        // console.log(maxHeight);
        // console.log(inputField.scrollHeight);
        inputField.style.height = Math.min(inputField.scrollHeight, maxHeight) + "px";
        if (inputField.value === "") {
            inputField.style.height = "fit-content";
        }
    }

    function getIdeaTitleSummary(prompt, imageGenerator, model) {
        if (loggedIn && userId) {
            setStoryPrompt(prompt);
            if (!isAdminOrDev) {
                console.log("Setting default values for non-admin user");
                setImageGenerator("Flux");
                setModel("gpt-4o-mini");
                // alert(`Debug - Non-admin values set: Flux, gpt-4o-mini, Prompt: ${prompt}`);
            } else {
                console.log("Setting admin-selected values");
                setImageGenerator(imageGenerator);
                setModel(model);
                // alert(`Debug - Admin values set: ${imageGenerator}, ${model}, Prompt: ${prompt}`);
            }
            setInputValue('');
            const inputField = document.getElementById('input-field');
            if (inputField) {
                inputField.style.height = "fit-content";
            }
        } else if (demoMode) {
            setStoryPrompt(prompt);
            setImageGenerator(null);
            setModel(null);
            // alert('Debug - Demo mode values set');
        } else {
            alert('Please log in to generate personalized stories.');
            setImageGenerator(null);
            setModel(null);
        }
    }

    function RefreshPrompts() {
        setPrompts(null);
        PopulatePrompts();
    }
    // ========================================= Page functions =========================================

    return (
        <>
            {activeProfileId ? (
                <div className='start-story show'>
                    <h1>Let's create a story!</h1>
                    {isAdminOrDev && (
                        <div className="dropdown-container">
                            <select 
                                value={imageGenerator} 
                                onChange={(e) => {
                                    setImageGenerator(e.target.value);
                                    setPrompts(null);
                                    setHasBeenCalled(false);
                                }}
                                className="neu-select"
                            >
                                <option value="">Select Illustrator</option>
                                <option value="DallE3">DALL-E 3 ($0.04/img)</option>
                                <option value="StableDiffusion">SD 3.5 Large Turbo ($0.04/img)</option>
                                <option value="Flux">Flux 1.1 Pro ($0.04/img)</option>
                            </select>
                            <select
                                value={model} 
                                onChange={(e) => {
                                    setModel(e.target.value);
                                    setPrompts(null);
                                    setHasBeenCalled(false);
                                }}
                                className="neu-select"
                            >
                                <option value="">Select Author</option>
                                <option value="gpt-4o">GPT-4o ($2.5/$10/1M)</option>
                                <option value="gpt-4o-mini">GPT-4o mini ($0.15/$0.60/1M)</option>
                                <option value="claude-3-5-sonnet-latest">Claude 3.5 Sonnet ($3/$15/1M)</option>
                                <option value="claude-3-haiku-20240307">Claude 3 Haiku ($0.25/$1.25/1M)</option>
                            </select>
                        </div>
                    )}
                    {isAdminOrDev && (!imageGenerator || !model) ? (
                        <p className='neu-text'>Please select both an image generator and a model to continue.</p>
                    ) : prompts === null ? (
                        <>
                            <Link to={`/story-gen-summary`} className="neu-button skeleton-loader">
                                <SkeletonLoader width="100%" height="40px" />
                            </Link>
                            <Link to={`/story-gen-summary`} className="neu-button skeleton-loader">
                                <SkeletonLoader width="100%" height="40px" />
                            </Link>
                        </>
                    ) : (
                        <>
                            {prompts.map((prompt, index) => (
                                <Link to={`/story-gen-summary`} key={index} onClick={() => getIdeaTitleSummary(prompt, imageGenerator, model)}>
                                    <button className="neu-button" key={index}>
                                        {prompt}
                                    </button>
                                </Link>
                            ))}
                            <button className="refresh-button" onClick={RefreshPrompts}>
                                <FaSyncAlt />
                            </button>
                        </>
                    )}
                    
                    {(isAdminOrDev ? (imageGenerator && model) : true) && (
                        <div className="input-group neu-input">
                            <textarea
                                value={inputValue}
                                onChange={(e) => {
                                    setInputValue(e.target.value);
                                    resizeInput(e);
                                }}
                                id="input-field"
                                className="input-field"
                                placeholder="Choose one of my ideas, or tell me what you want to read a story about here!"
                            />
                            {loggedIn ? (
                                <>
                                    {inputValue === "" ? (
                                        <button className="neu-send-button" style={{opacity: '50%'}}>
                                            Let's Go!
                                        </button>
                                    ) : (
                                        <Link to={`/story-gen-summary`} onClick={() => getIdeaTitleSummary(inputValue, imageGenerator, model)}>
                                            <button className="neu-send-button">
                                                Let's Go!
                                            </button>
                                        </Link>
                                    )}
                                </>
                            ) : (
                                <Link to={`/login`}>
                                    <button className="neu-send-button">
                                        Login to personalize!
                                    </button>
                                </Link>
                            )}
                        </div>
                    )}
                    
                </div>
            ) : (
                <div className='start-story'></div>
                // <>
                //     <Link to={`/story-gen-summary`} className="neu-button skeleton-loader">
                //         <SkeletonLoader width="100%" height="40px" />
                //     </Link>
                //     <Link to={`/story-gen-summary`} className="neu-button skeleton-loader">
                //         <SkeletonLoader width="100%" height="40px" />
                //     </Link>
                // </>
            )}
        </>
    );
};

export default StoryGenStartPage;